* {
  box-sizing: border-box;
}

html [id], body [id] {
	scroll-margin: 100px !important;
}

img {
	user-select: none;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background: linear-gradient(to right, hsla(230, 96%, 62%, 0.251), hsla(348, 97%, 63%, 0.251)); */

  /* background: linear-gradient(to right, hsla(206.57,89.74%,54.12%, .5), hsla(87.86,50.00%,67.06%, 0.5)); */
  background: #fff;
  /* color: hsl(206, 62%, 19%); */
  color: hsl(170, 100%, 11%);
  font-weight:normal;
  /* background: linear-gradient(to right, hsla(229, 96%, 62%, 0.251), hsla(108, 97%, 63%, 0.251)); */
  /* background: #B2EBF2; */
   /* background: #fff;  */
  /* background: linear-gradient(to right, #43cea2, #185a9d); */

  /* overflow-x: auto; */

  padding: 0px;

  scrollbar-gutter: stable both-edges;

  font-size: 16px;
}



.App {
  width: 100%;
}

/* a {
  all:unset;
} */

a:link {
  
  text-decoration: none;
  cursor: pointer;	
}	


a:visited	{
  all: unset;
  text-decoration: none;
  cursor: pointer;	
}
/* 
a:link:active	{
  color: inherit;
} */

/* a:visited:active {
  color: inherit;
}	 */

code[class*="language-"],
pre[class*="language-"] {
	color: #393A34;
	font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	font-size: .9em;
	line-height: 1.2em;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

pre > code[class*="language-"] {
	font-size: 1em;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
	background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
	background: #b3d4fc;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border: 1px solid #dddddd;
	background-color: white;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .2em;
	padding-top: 1px;
	padding-bottom: 1px;
	background: #f8f8f8;
	border: 1px solid #dddddd;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #999988;
	font-style: italic;
}

.token.namespace {
	opacity: .7;
}

.token.string,
.token.attr-value {
	color: #2196F3;
}

.token.punctuation,
.token.operator {
	color: #393A34; /* no highlight */
}

.token.entity,
.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.property,
.token.regex,
.token.inserted {
	color: #36acaa;
}

.token.annotation {
  font-weight: bold;
  color: #673AB7;
}

.token.atrule,
.token.keyword,
.token.attr-name,
.language-autohotkey .token.selector {
	color: #009688;
  font-weight: bold;
}

.token.function,
.token.deleted,
.language-autohotkey .token.tag {
	color: #B71C1C;
}

.token.tag,
.token.selector,
.language-autohotkey .token.keyword {
	color: #00009f;
}

.token.important,
.token.function,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.desktop-only {
	display: block !IMPORTANT;
}

.desktop-only-flex {
	display: flex !IMPORTANT;
}

.mobile-only {
	display: none !IMPORTANT;
}

@media only screen and (max-width: 768px) {
	.desktop-only {
		display: none !IMPORTANT;
	}

	.desktop-only-flex {
		display: none !IMPORTANT;
	}

	.mobile-only {
		display: block !IMPORTANT;
	}
}

